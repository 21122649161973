import { useNavigate } from "react-router-dom";

import { authenticationConfirm } from "@/api/authentication-confirm";
import { useAuthorizationConfirm } from "@/hooks/useAuthorizationConfirm";

import { path } from "@/routes";

import { useLeaveInteraction } from "./useLeaveInteraction";

export const useAuthenticationConfirm = () => {
  const navigate = useNavigate();
  const authorizationConfirm = useAuthorizationConfirm();
  const leaveInteraction = useLeaveInteraction();
  const callConfirmAndNavigateLocation = async (
    interactionId: string,
    mfaSkip: boolean | undefined = undefined,
    replace = false,
  ): Promise<void> => {
    const location = await callConfirmAndGetLocation(
      interactionId,
      mfaSkip,
      replace,
    );

    if (typeof location === "string") await leaveInteraction.redirect(location);
  };

  const callConfirmAndGetLocation = async (
    interactionId: string,
    mfaSkip: boolean | undefined = undefined,
    replace = false,
  ): Promise<string | undefined> => {
    try {
      const authenticationResponse = await authenticationConfirm(
        interactionId,
        mfaSkip,
      );
      if (authenticationResponse.data.state === "login_completed") {
        return await authorizationConfirm.callConfirmAndGetLocation(
          interactionId,
        );
      }
      if (authenticationResponse.data.state === "change_password_required") {
        navigate(path.login.registerStablePassword.root, { replace });
      }
    } catch (_) {
      navigate(path.login.error, { replace });
    }
  };

  return {
    callConfirmAndNavigateLocation: callConfirmAndNavigateLocation,
  };
};
