import { Suspense } from "react";

import { I18nextProvider } from "react-i18next";
import { RouterProvider, type RouterProviderProps } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import i18n from "./lib/locale/i18n";
import LoadingPage from "./pages/Dialog/Loading";

const App = (props: RouterProviderProps) => {
  return (
    <HelmetProvider>
      <Suspense fallback={<LoadingPage />}>
        <I18nextProvider i18n={i18n}>
          <RouterProvider {...props} />
        </I18nextProvider>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
